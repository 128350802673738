import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  Box,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { MdSearch } from 'react-icons/md'
import { GetGlobalSearchResult, GlobalSearchResultType } from 'types/graphql'

import { Link, navigate, routes } from '@redwoodjs/router'

import { useDebounce } from 'src/hooks/useDebounce'

import { CustomInput } from '../Shared/CustomInput/CustomInput'

import GlobalSearchResultItemBox from './GlobalSearchResultItem'

export const GET_GLOBAL_SEARCH_RESULT = gql`
  query GetGlobalSearchResult($query: String!) {
    getGlobalSearchResult(query: $query) {
      items {
        entityId
        title
        subtitle
        type
        isActive
      }
    }
  }
`

interface GlobalSearchModalProps {
  isOpen: boolean
  onClose: () => void
}

const GlobalSearchModal: React.FC<GlobalSearchModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [query, setQuery] = useState('')
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const debouncedQuery = useDebounce(query, 1000)

  const [fetchSearchResults, { loading, data }] =
    useLazyQuery<GetGlobalSearchResult>(GET_GLOBAL_SEARCH_RESULT, {
      variables: { query: debouncedQuery },
      fetchPolicy: 'no-cache',
    })

  const itemRefs = useRef<(HTMLLIElement | null)[]>([])

  useEffect(() => {
    if (isOpen) setSelectedIndex(null)
  }, [isOpen, debouncedQuery])

  const onItemSelected = useCallback(() => {
    setQuery('')
    setSelectedIndex(null)

    onClose()
  }, [onClose])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!data || !data.getGlobalSearchResult.items.length) return

      if (event.key === 'ArrowDown') {
        event.preventDefault()
        setSelectedIndex((prevIndex) => {
          if (prevIndex === null) {
            return 0
          } else if (prevIndex < data.getGlobalSearchResult.items.length - 1) {
            return prevIndex + 1
          }

          return prevIndex
        })
      } else if (event.key === 'ArrowUp') {
        event.preventDefault()
        setSelectedIndex((prevIndex) => {
          if (prevIndex > 0) {
            return prevIndex - 1
          }

          return prevIndex
        })
      } else if (event.key === 'Enter' && selectedIndex !== null) {
        const selectedItem = data.getGlobalSearchResult.items[selectedIndex]
        navigate(getEntityRoute(selectedItem.entityId, selectedItem.type))
        onItemSelected()
      }
    },
    [data, selectedIndex, onItemSelected]
  )

  useEffect(() => {
    if (selectedIndex !== null && itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex]?.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
      })
    }
  }, [selectedIndex])

  useEffect(() => {
    if (debouncedQuery) {
      fetchSearchResults()
    }
  }, [fetchSearchResults, debouncedQuery])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value
    setQuery(newQuery)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent top="4vh" borderRadius={'8px'} onKeyDown={handleKeyDown}>
        <ModalHeader p={0}>
          <CustomInput
            size={'lg'}
            placeholder="Search for everything"
            border="none"
            minHeight={'68px'}
            paddingStart={'68px'}
            paddingEnd={0}
            fontWeight={'medium'}
            _focusVisible={{ border: 'none' }}
            value={query}
            onChange={handleSearch}
            startElement={<MdSearch color="#162a6c" size="30px" />}
            startElementProps={{
              pointerEvents: 'none',
              minHeight: '68px',
              marginStart: '16px',
            }}
          />
        </ModalHeader>
        {query && query.length > 1 && (
          <ModalBody py={0} overflow={'auto'} maxHeight={'66vh'}>
            <Box borderTopWidth={'1px'}>
              {loading ? (
                <Spinner size="lg" mt={8} pt={2} pb={4} />
              ) : data && data.getGlobalSearchResult.items.length > 0 ? (
                <List spacing={3} pt={2} pb={4}>
                  {data.getGlobalSearchResult.items.map((item, index) => {
                    const to = getEntityRoute(item.entityId, item.type)
                    return (
                      <ListItem
                        key={index}
                        ref={(el) => (itemRefs.current[index] = el)} // Assign each item to itemRefs
                        style={{ marginTop: '8px' }}
                      >
                        <Link to={to} onClick={onItemSelected}>
                          <GlobalSearchResultItemBox
                            key={index}
                            item={item}
                            isSelected={index === selectedIndex}
                          />
                        </Link>
                      </ListItem>
                    )
                  })}
                </List>
              ) : (
                query && (
                  <Box
                    pt={2}
                    pb={4}
                    color="gray.700"
                    fontWeight={'semibold'}
                    style={{ marginTop: '8px' }}
                  >
                    No results found
                  </Box>
                )
              )}
            </Box>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

const getEntityRoute = (entityId: number, type: GlobalSearchResultType) => {
  switch (type) {
    case 'Employee':
      return routes.employee({ id: entityId })
    case 'Customer':
      return routes.customer({ id: entityId })
    case 'Supplier':
      return routes.supplier({ id: entityId })
    default:
      return '#'
  }
}

export default GlobalSearchModal
